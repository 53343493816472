import $ from 'jquery';
import Slick from 'slick-carousel';

export default function() {
	$('.js-temoignages').slick({
		dots:true,
		arrows:false,
		cssEase: 'ease',
		swipe:true,
		cssEase: 'linear',
		pauseOnHover:true,
		easing:'linear',
		speed:1000,
		autoplay:true,
	});
}