import $ from 'jquery';

export default function () {

var $window = $(window);
$window.scroll(function() {
  var $scroll_position = $window.scrollTop();
    if ($scroll_position > $window.innerHeight()) { // if body is scrolled down by 300 pixels
        $('.c-header').addClass('sticky');
        // to get rid of jerk
        var header_height = $('.c-header').innerHeight();
        $('body').css('padding-top' , 0);
    } else {
        $('body').css('padding-top' , '0');
        $('.c-header').removeClass('sticky');
    }
 });
}