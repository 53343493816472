import $ from 'jquery';

export default function () {

	setTimeout(step1, 300);
	setTimeout(step2, 1500);
	setTimeout(step3, 3500);
	setTimeout(step4, 5200);
	setTimeout(step5, 6200);
	setTimeout(step6, 6800);

	function step1() {
  		$('.home-animation').addClass('step-1');
	}

	function step2() {
  		$('.home-animation').addClass('step-2');
	}

	function step3() {
  		$('.home-animation').addClass('step-3');
	}

	function step4() {
  		$('.home-animation').addClass('step-4');
	}

	function step5() {
  		$('.home-animation').addClass('step-5');
	}

	function step6() {
  		$('.home-animation').addClass('step-6');
	}

}