import $ from 'jquery';

export default function () {
    var menuOuvert = false;

	$(window).scroll(function(event){
		$('.js-mobile-nav').removeClass('active');
	});

	$('.c-mobile__icon').click(function(event){
		event.stopPropagation();
		event.preventDefault();

		if(!menuOuvert){
			$('.js-mobile-nav').addClass('active');
			menuOuvert = true;
		}
		else{
			$('.js-mobile-nav').removeClass('active');
			menuOuvert = false;
		}
	});

	$('.js-menu-button.close').click(function() {
		$('.c-mobile-nav').removeClass('active');
	})

}