import './modules/helpers';
import start from './modules/router';
import mobileNav from './modules/mobileNav';
import loader from './modules/loader';
import scroller from './modules/scroller';
import homeAnimation from './modules/homeAnimation';
import tabs from './modules/tabs';
import temoignages from './modules/temoignages';
import formSelector from './modules/formSelector';
import panes from './modules/panes';
import parallax from './modules/parallax';
import sticky from './modules/sticky';

mobileNav();
loader();
scroller();
homeAnimation();
tabs();
temoignages();
formSelector();
panes();
parallax();
sticky();