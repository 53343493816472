import $ from 'jquery';

export default function() {

	function parallax() {
		
		var scrolled = $(window).scrollTop();

		$('.wrapper').css('background-position-y', -(scrolled * 0.3));
		$('.circle-left').css('top', (1200 - scrolled * 0.5));
		$('.circle-right').css('bottom', (200 - scrolled * 0.2));
		$('.bottom-circle-left').css('top', (1000 - scrolled * 0.3));
		$('.bottom-circle-right').css('bottom', (1200 - scrolled * 0.2));
	};

	$(window).scroll(function(e){
		parallax();
	});
}