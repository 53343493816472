import $ from 'jquery';

export default function () {

	//Fonction to strigger scroll to different desctions in a page
	$('.scrollTo').click(function(event){
		event.preventDefault();

		var offset = 0;

		if($(this).attr('data-offset')){
			offset = $(this).attr('data-offset');
		}

		smoothScroll($(this).attr('href'), offset);

		return false;
	});

	function smoothScroll(section, offset){ // Smoothscroll to a section
		$('html, body').animate({ scrollTop: $("#"+section).offset().top + parseInt(offset)}, 500);
	} 

}
