import $ from 'jquery';

export default function() {

	$('.js-open-pane').click(function(e) {
		e.preventDefault();
		$('.c-panes').removeClass('active');
		$('.c-panes.'+ $(this).attr('data-pane-class') ).addClass('active');
	});

	$('.c-panes__close').click(function(e){
		e.preventDefault();
		$('.c-panes').removeClass('active');
	});

	$(document).keydown(function(e) {
	    if (e.keyCode == 27) {
	        $('.c-panes').removeClass('active');
	    }
	});


	$(document).ready(function() {

		var paneText = $('.c-panes__text').height()/2;

		$('.c-panes__text').css('margin-top', paneText);
	})

}