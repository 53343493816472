import $ from 'jquery';

export default function () {	

	var tabs = ['tab-1', 'tab-2', 'tab-3'];
	var current_index = 0;

	function setTabs() {
		$('.c-tabs__item').removeClass('active');
		$('.c-tabs__content').removeClass('active');

		$('.c-tabs__item[data-tab="'+ tabs[current_index] +'"]').addClass('active');
		$("#"+tabs[current_index]).addClass('active');
	};

	$('.c-tabs__item').click(function(){
		var tab_id = $(this).attr('data-tab');

		current_index = tabs.indexOf(tab_id);

		setTabs();
	});

	$('.js-tab-previous').click(function(e) {
		e.preventDefault();

		if(current_index == 0) {
			current_index = 2;
		} else {
			current_index--;
		}

		setTabs();
	});

	$('.js-tab-next').click(function(e) {
		e.preventDefault();

		if(current_index == 2) {
			current_index = 0;
		} else {
			current_index++;
		}

		setTabs();
	});

}