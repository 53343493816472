import $ from 'jquery';

export default function () {

	$('.mm-form__item input').focus(function() {
		$(this).closest('.mm-form__item').addClass('active');
	})

	$('.mm-form__item input').blur(function() {
		var $this = $(this);

		if($this.val() == '') {
			$(this).closest('.mm-form__item').removeClass('active');
		}
	})
}